import loadable from '@loadable/component';
import { useTrackingInstance } from '@rategravity/1pt-lib';
import React, { Fragment } from 'react';
import { LandingPageLayout } from '../components/layout';
import { NumberedSection } from '../components/numbered-section';
import { Heroic } from '../components/reason/heroic';
import {
  BannerImage,
  reasonsImages,
  whyOwnUpImages
} from '../components/static-images/old/3-reasons';
import { reasonsData, whyOwnUpData } from '../data/content/3-reasons';
import { colors } from '../modules/colors';

const Divider = loadable(() => import('../components/divider'), {
  resolveComponent: (components) => components.Divider
});

const FullWidthField = loadable(() => import('../components/full-width-field'), {
  resolveComponent: (components) => components.FullWidthField
});

const FullWidthFieldHeader = loadable(() => import('../components/full-width-field'), {
  resolveComponent: (components) => components.FullWidthFieldHeader
});

const Spacer = loadable(() => import('../components/layout/spacers'), {
  resolveComponent: (components) => components.Spacer
});

const GreenWithHouse = loadable(() => import('../components/green-with-house'), {
  resolveComponent: (components) => components.GreenWithHouse
});

type ReasonsData = typeof reasonsData;

const Reasons = ({ reasons }: { reasons: ReasonsData }) => {
  const hook = useTrackingInstance();
  return reasons.map((data, index) => {
    const Image = reasonsImages[data.imageName];
    const trackingEvent = {
      hook,
      name: 'clickDiscoverCTA',
      properties: {
        'page-location': `3R-ownup-${index}`
      }
    };

    return (
      <Fragment key={`reason-${index + 1}`}>
        <NumberedSection
          {...data}
          Image={Image}
          label="Reasons you're overpaying"
          trackingEvent={trackingEvent}
        />
        {index < reasonsData.length - 1 ? <Divider /> : null}
      </Fragment>
    );
  });
};

type WhyOwnUpData = typeof whyOwnUpData;

const WhyOwnUp = ({
  whyOwnUpReasons,
  trackingInstance
}: {
  whyOwnUpReasons: WhyOwnUpData;
  trackingInstance: ReturnType<typeof useTrackingInstance>;
}) => {
  return whyOwnUpReasons.map((data, index) => {
    const Image = whyOwnUpImages[data.imageName];
    const sectionKey = `why-own-up-${index + 1}`;
    const trackingEvent = {
      hook: trackingInstance,
      name: 'clickDiscoverCTA',
      properties: {
        'page-location': sectionKey
      }
    };

    return (
      <Fragment key={sectionKey}>
        <NumberedSection
          {...data}
          Image={Image}
          label="Why use Own Up"
          trackingEvent={trackingEvent}
        />
        {index < whyOwnUpData.length - 1 ? <Divider /> : null}
      </Fragment>
    );
  });
};

export const ThreeReasons = () => {
  const trackingInstance = useTrackingInstance();

  return (
    <LandingPageLayout>
      <main>
        <Heroic BannerImage={<BannerImage />} />
        {Reasons({ reasons: reasonsData })}
        <Spacer />
        <FullWidthField backgroundColor={colors.LIGHT_GREY}>
          <FullWidthFieldHeader>
            We created Own Up to get you the best deal on your mortgage and to protect you from
            these 3 expensive pitfalls:
          </FullWidthFieldHeader>
        </FullWidthField>
        <Spacer />
        {WhyOwnUp({ whyOwnUpReasons: whyOwnUpData, trackingInstance })}
        <GreenWithHouse page="/3-reasons/" />
      </main>
    </LandingPageLayout>
  );
};

export default ThreeReasons;

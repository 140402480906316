import React, { Fragment } from 'react';
import { UnderlinedLinkInternal } from '../../../components/links';
import { OWN_UP_FEE } from '../constants';

export const reasonsData = [
  {
    count: 1,
    header: 'Your loan officer is paid 1% of your loan amount.',
    imageName: 'banker.png' as const,
    imagePosition: 'right' as const,
    text: (
      <Fragment>
        <span>
          The commission your lender pays to their salesperson is rolled into the cost of your loan
          in the form of a higher interest rate &mdash; sometimes as much as 0.375%. On a $400,000
          loan, that can mean more than $30,000 in excess interest payments.
        </span>
        <br />
        <br />
        <span>
          This is not to say MLOs should not be paid, but rather you have power to negotiate and get
          a better deal.
        </span>
      </Fragment>
    )
  },
  {
    count: 2,
    header: 'You think all mortgage companies are the same.',
    imageName: 'black-dress-same.png' as const,
    imagePosition: 'left' as const,
    text: (
      <Fragment>
        <span>
          While interest rates are usually in a similar range, every lending institution has a
          different level of tolerance for different homeowner profiles.
        </span>
        <br />
        <br />
        <span>
          Some lenders prefer condos, but others don’t. Other lenders are comfortable with people
          who work as freelancers or entrepreneurs, but others are less open. Those types of
          variances can add as much as 0.50% to the market interest rate.
        </span>
      </Fragment>
    )
  },
  {
    count: 3,
    header: 'You assume every homebuyer is treated equally.',
    imageName: 'justice.png' as const,
    imagePosition: 'right' as const,
    text: 'MLOs pick their compensation plan, which means they have the authority to set your interest rate. That’s a lot of discretionary power, and those trade-offs impact the interest rate you receive. This means that if you talk to the wrong salesperson, you can overpay on your mortgage by tens of thousands of dollars.'
  }
];

export const whyOwnUpData = [
  {
    count: 1,
    header: `Our lenders pay us .${OWN_UP_FEE}%. We are the only company to openly disclose our compensation.`,
    imageName: 'quarter.png' as const,
    imagePosition: 'right' as const,
    text: (
      <Fragment>
        <span>
          Every lender pays us the same amount, so we are solely focused on finding you the best
          deal. We charge our lenders a fraction of the average fee (.{OWN_UP_FEE}% vs. 1%), and
          those savings get returned to you in the form of a lower interest rate.
        </span>
        <br />
        <br />
        <span>
          So, does it work? Don’t take it from us, check out the{' '}
          <strong>
            <UnderlinedLinkInternal to="/reviews/">
              reviews from hundreds of satisfied customers
            </UnderlinedLinkInternal>
          </strong>
          . Our average customer saves more than{' '}
          <UnderlinedLinkInternal to="/methodology/">$27,000</UnderlinedLinkInternal> over the life
          of their loan.
        </span>
      </Fragment>
    ),
    callToAction: 'SEE HOW MUCH YOU CAN SAVE'
  },
  {
    count: 2,
    header:
      'We shop a network of top lenders to get you personalized rates. We will NEVER sell your information.',
    imageName: 'mike-and-patrick.jpg' as const,
    imagePosition: 'left' as const,
    text: 'We evaluate your profile, shop a network of certified lenders that match perfectly to you, and your personal information remains completely anonymous.',
    callToAction: 'FIND YOUR PERSONALIZED RATE'
  },
  {
    count: 3,
    header: 'Every Own Up customer has complete transparency into the mortgage process.',
    imageName: 'cody-and-erica.jpg' as const,
    imagePosition: 'right' as const,
    text: (
      <Fragment>
        <span>
          Everyone is treated equally, and no one at Own Up can use their personal discretion to
          mark up or down the offer terms.
        </span>
        <br />
        <br />
        <span>
          But, you don’t have to take our word for it. In our loan process, we “flip our screen” so
          you can see the offers from our lender network exactly as we see them.
        </span>
      </Fragment>
    ),
    callToAction: 'VIEW YOUR OPTIONS'
  }
];

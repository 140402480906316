import { buildMediaQueries } from '@rategravity/marketing-components';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  fonts,
  OwnUpHeader,
  OwnUpText
} from '@rategravity/own-up-component-library-legacy';
import styled from 'styled-components';
import { colors } from '../../modules/colors';
import { ResponsivePrimaryTextMixin } from '.';

export const LandingPageSectionTitleText = styled(OwnUpText)`
  ${ResponsivePrimaryTextMixin}
  margin-bottom: 10px;
  ${fonts.MONOSTEN_LIGHT};
  font-family: 'Monosten', 'Arial', 'sans-serif';
  color: ${colors.PRIMARY};
  font-size: 14px;
  text-align: center;
  letter-spacing: 1px;
  line-height: 18px;
  ${buildMediaQueries('mediumAndDown', {
    marginBottom: '30px'
  })}
`;

export const LandingPageHeaderTextStyle = createOwnUpStyle({
  fontSize: '48px',
  lineHeight: '61px',
  marginBottom: '50px',
  ...fonts.TIEMPOS_HEADLINE_REGULAR,
  fontWeight: 500,
  color: colors.PRIMARY,
  textAlign: 'center',
  variants: {
    mediumAndDown: {
      marginBottom: '30px',
      fontSize: '33px',
      lineHeight: '42px'
    }
  }
});

export const LandingPageHeaderText = styled(OwnUpHeader)`
  font-size: 48px;
  line-height: 61px;
  margin-bottom: 50px;
  ${fonts.TIEMPOS_HEADLINE_REGULAR}
  font-weight: 500;
  color: ${colors.PRIMARY};
  text-align: 'center';
  ${buildMediaQueries('mediumAndDown', {
    marginBottom: '30px',
    fontSize: '33px',
    lineHeight: '42px'
  })}
`;

export const LandingPageMainTextStyle = createOwnUpStyle({
  ...fonts.GRAPHIK_REGULAR,
  fontSize: '20px',
  lineHeight: '124%',
  textAlign: 'center',
  color: colors.LOGO_SHADOW,
  variants: {
    smallAndDown: {
      fontSize: '15px'
    }
  }
});

export const LandingPageMainText = createOwnUpComponent(OwnUpHeader, LandingPageMainTextStyle);

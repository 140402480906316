import { buildMediaQueries } from '@rategravity/marketing-components';
import {
  aktivGroteskMediumMixin,
  FOG_20,
  MYSTIC_40,
  WALDEN_100
} from '@rategravity/own-up-component-library';
import { ArrowRightIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/arrow-right';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  fonts,
  OwnUpBox
} from '@rategravity/own-up-component-library-legacy';
import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../modules/colors';

/**
 * Styling that will underpin (most of) the buttons. Some properties will are assigned 'unset', in order to remove the defaults that come in from the component library.
 */
const ButtonStyle = createOwnUpStyle({
  ...fonts.GRAPHIK_MEDIUM,
  fontFamily: "'Graphik-Medium', 'Arial', 'sans-serif'",
  backgroundColor: 'transparent',
  borderWidth: 2,
  borderStyle: 'solid',
  borderRadius: 25,
  boxSizing: 'border-box',
  fontSize: 15,
  height: 'unset',
  letterSpacing: 2,
  lineHeight: 1.2,
  minWidth: 'unset',
  paddingTop: 0,
  textAlign: 'center',
  textTransform: 'unset',
  transition: '0.7s', // Most buttons have a hover state.
  whiteSpace: 'unset'
});

const ButtonStyleMixin = css`
  font-family: 'Graphik-Medium', 'Arial', 'sans-serif';
  background-color: transparent;
  border-width: 2px;
  border-style: solid;
  border-radius: 25px;
  box-sizing: border-box;
  font-size: 15px;
  height: unset;
  letter-spacing: 2px;
  line-height: 1.2;
  min-width: unset;
  padding-top: 0;
  text-align: center;
  text-transform: unset;
  transition: 0.7s;
  white-space: unset;
`;

/**
 * Styling for the blue button that appears in the heroic banner as well as the header on small screens.
 */
const HeroicButtonStyle = createOwnUpStyle({
  ...ButtonStyle,
  backgroundColor: colors.CLASSIC_BLUE,
  border: 'unset',
  color: colors.WHITE,
  transition: 'unset',
  variants: {
    banner: {
      padding: '15px 60px',
      maxWidth: 250
    },
    header: {
      animation: 'fadeIn 1s',
      fontSize: 12,
      padding: '12px 24px',
      maxWidth: 150,
      '@keyframes fadeIn': {
        from: {
          opacity: 0
        },
        to: {
          opacity: 1
        }
      }
    },
    bestMortgageBanner: {
      borderRadius: '100px',
      letterSpacing: '.5px',
      width: 325,
      padding: 20,
      variants: {
        smallAndDown: {
          width: '100%'
        }
      }
    }
  }
});

export const HeroicButton = createOwnUpComponent(OwnUpBox, HeroicButtonStyle);

/**
 * Styling for the buttons that appear in the body of the page (i.e., not in the header, footer, or banners).
 */
const CallToActionButtonBaseStyle = css`
  ${ButtonStyleMixin};
  border-color: ${colors.CLASSIC_BLUE};
  border-width: 3px;
  color: ${colors.CLASSIC_BLUE};
  padding: 12px 24px;
  text-transform: uppercase;
  :hover {
    background-color: ${colors.CLASSIC_BLUE};
    color: ${FOG_20};
  }
  ${buildMediaQueries(['small', 'medium', 'large'], {
    minWidth: '250px'
  })}
`;

export const CallToActionButton = styled(OwnUpBox)`
  ${CallToActionButtonBaseStyle}
`;

/**
 * Styling for the buttons that appear in the green banner with the house.
 */
export const GreenBannerButton = styled(OwnUpBox)`
  ${ButtonStyleMixin};
  border-color: ${colors.PRIMARY};
  border-width: 3px;
  color: ${colors.PRIMARY};
  width: 200px;
  padding: 12px 24px;
  :hover {
    background-color: ${colors.PRIMARY};
    color: ${colors.WHITE};
  }
  ${buildMediaQueries(['small', 'medium', 'large'], {
    minWidth: '250px'
  })}
`;

const WrapperButtonStyle = createOwnUpStyle({
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  padding: 0
});

export const WrapperButton = createOwnUpComponent('button', WrapperButtonStyle);

const LinkedButtonText = styled.span`
  margin-right: 8px;
`;
const LinkedButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  color: ${WALDEN_100};
  background-color: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  ${aktivGroteskMediumMixin}
  font-size: 1rem;
  &:hover {
    background-color: ${MYSTIC_40};
  }
  svg {
    path {
      fill: ${WALDEN_100};
    }
  }
`;

export const LinkedButton = ({ children, onClick }: PropsWithChildren<{ onClick: () => void }>) => {
  return (
    <LinkedButtonWrapper onClick={onClick}>
      <LinkedButtonText>{children}</LinkedButtonText>
      <ArrowRightIcon />
    </LinkedButtonWrapper>
  );
};

import {
  OwnUpBody,
  ownUpBodyMediumMixin,
  ownUpLargeBodyMixin
} from '@rategravity/own-up-component-library';
import styled from 'styled-components';

export * from './www';
export * from './icon-landing';
export * from './landing-pages';

/**
 * Use this instead of the import from the component library.
 * For some reason, styled components consistently drops
 * the Medium mixin in favor of the Regular mixin.
 */
export const OwnUpLargeBodyMedium = styled(OwnUpBody)`
  ${ownUpLargeBodyMixin}
  ${ownUpBodyMediumMixin}
`;

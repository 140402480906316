import {
  buildMediaQueries,
  GetStartedLink,
  TrackingEvent
} from '@rategravity/marketing-components';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpBox
} from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import styled, { css } from 'styled-components';
import { onboarding } from '../../modules/navigation';
import { CallToActionButton } from '../button';
import { SectionCount } from '../count';
import { DottedImage } from '../dotted-image';
import { LinkWrapper } from '../links';
import { ResponsivePrimaryText, ResponsiveSectionHeader } from '../typography';
export interface NumberedSectionProps {
  count: number;
  header: string;
  imagePosition: 'right' | 'left';
  Image: JSX.Element | null;
  text: string | JSX.Element;
  callToAction?: string;
  label?: string;
  trackingEvent?: TrackingEvent;
}

const NumberedSectionWrapperStyle = createOwnUpStyle({
  padding: '30px 64px',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  variants: {
    mediumAndDown: {
      display: 'block',
      padding: 30
    }
  }
});

const NumberedSectionWrapper = createOwnUpComponent('section', NumberedSectionWrapperStyle);

const ContentWrapperBaseStyle = css`
  display: flex;
  flex-direction: column;
  position: relative;
  width: calc(50% - 65px);
  ${buildMediaQueries('mediumAndDown', {
    width: 'unset',
    margin: '0 0 30px'
  })}
  ${buildMediaQueries('medium', {
    padding: '30px 60px 60px'
  })}
  ${buildMediaQueries('small', {
    padding: '30px'
  })}
`;

const ContentWrapperImageLeft = styled(OwnUpBox)`
  ${ContentWrapperBaseStyle}
  ${buildMediaQueries('large', {
    marginLeft: '65px'
  })}
`;

const ContentWrapperImageRight = styled(OwnUpBox)`
  ${ContentWrapperBaseStyle}
  ${buildMediaQueries('large', {
    marginRight: '65px'
  })}
`;

const ImageWrapperStyle = createOwnUpStyle({
  width: '50%',
  '& img': {
    borderRadius: '50%'
  },
  variants: {
    mediumAndDown: {
      paddingBottom: 30,
      width: '100%'
    }
  }
});

const ImageWrapper = createOwnUpComponent(OwnUpBox, ImageWrapperStyle);

const ImageComponent = ({ Image }: NumberedSectionProps) => (
  <ImageWrapper>
    <DottedImage Image={Image} />
  </ImageWrapper>
);

const CallToActionStyle = createOwnUpStyle({
  marginTop: 40,
  variants: {
    mediumAndDown: {
      marginTop: 20,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  }
});

const CallToAction = createOwnUpComponent(OwnUpBox, CallToActionStyle);

const NumberedSectionHeaderStyle = createOwnUpStyle({
  variants: {
    smallAndDown: {
      wordBreak: 'break-word'
    }
  }
});

const NumberedSectionHeader = createOwnUpComponent(
  ResponsiveSectionHeader,
  NumberedSectionHeaderStyle
);

export const NumberedSection = (props: NumberedSectionProps) => {
  const content = (
    <>
      <SectionCount>{props.count}</SectionCount>
      <NumberedSectionHeader>{props.header}</NumberedSectionHeader>
      <ResponsivePrimaryText>{props.text}</ResponsivePrimaryText>
      {props.callToAction ? (
        <CallToAction>
          <LinkWrapper>
            <GetStartedLink
              onboarding={onboarding}
              trackingEvent={props.trackingEvent}
              url={`${process.env.GATSBY_ONBOARDING}`}
            >
              <CallToActionButton>{props.callToAction}</CallToActionButton>
            </GetStartedLink>
          </LinkWrapper>
        </CallToAction>
      ) : null}
    </>
  );

  return (
    <NumberedSectionWrapper aria-label={`${props.label || 'section'} ${props.count}`}>
      {props.imagePosition === 'left' ? <ImageComponent {...props} /> : null}
      {props.imagePosition == 'left' ? (
        <ContentWrapperImageLeft>{content}</ContentWrapperImageLeft>
      ) : (
        <ContentWrapperImageRight>{content}</ContentWrapperImageRight>
      )}
      {props.imagePosition === 'right' ? <ImageComponent {...props} /> : null}
    </NumberedSectionWrapper>
  );
};

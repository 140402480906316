import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { reasonsImgStyle as imgStyle } from '.';

const QuarterImage = () => (
  <StaticImage
    src="../../../../images/quarter.png"
    alt="U.S. quarter dollar coin"
    placeholder="blurred"
    quality={100}
    style={imgStyle}
  />
);

const MikePatrickImage = () => (
  <StaticImage
    src="../../../../images/mike-and-patrick.jpg"
    alt="two men sitting at table with laptop"
    placeholder="blurred"
    quality={100}
    style={imgStyle}
  />
);

const CodyEricaImage = () => (
  <StaticImage
    src="../../../../images/cody-and-erica.jpg"
    alt="smiling woman with sunglasses and smiling man with sunglasses"
    placeholder="blurred"
    quality={100}
    style={imgStyle}
  />
);

export const whyOwnUpImages = {
  'quarter.png': <QuarterImage />,
  'mike-and-patrick.jpg': <MikePatrickImage />,
  'cody-and-erica.jpg': <CodyEricaImage />
};

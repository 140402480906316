import { OwnUpBox } from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import GreenDotsBlobSVG from '../../images/green-dots-blob.svg';

interface DottedImageProps {
  Image: JSX.Element | null;
}

/**
 * An image that is overlaid on a dotted background.
 *
 * The image is injected as a dependency to enable
 * development with Storybook.
 */
export const DottedImage = ({ Image }: DottedImageProps) => {
  return (
    <OwnUpBox
      style={{
        margin: 'auto',
        maxWidth: 510,
        backgroundImage: `url(${GreenDotsBlobSVG})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        smallAndDown: {
          backgroundSize: 'cover'
        }
      }}
    >
      {Image}
    </OwnUpBox>
  );
};

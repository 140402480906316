import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export * from './reasons';
export * from './why-own-up';

export const reasonsImgStyle = {
  maxWidth: '390px',
  margin: 'auto',
  position: 'relative' as const,
  borderRadius: '50%',
  display: 'inherit'
};

export const BannerImage = () => (
  <StaticImage
    src="../../../../images/3-reasons-banner.jpeg"
    alt="hands reaching across table to shake on a deal"
    placeholder="blurred"
    quality={100}
    loading="eager"
    style={{
      position: 'absolute',
      height: '100%',
      width: '100%'
    }}
  />
);

import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { reasonsImgStyle as imgStyle } from '.';

const BankerImage = () => (
  <StaticImage
    src="../../../../images/banker.png"
    alt="smiling man with dress shirt and suit jacket"
    placeholder="blurred"
    quality={100}
    style={imgStyle}
  />
);

const BlackDressImage = () => (
  <StaticImage
    src="../../../../images/black-dress-same.png"
    alt="dark dresses on black hangers on a white closet rod"
    placeholder="blurred"
    quality={100}
    style={imgStyle}
  />
);

const JusticeImage = () => (
  <StaticImage
    src="../../../../images/justice.png"
    alt="statue of Lady Justice with blindfold"
    placeholder="blurred"
    quality={100}
    style={imgStyle}
  />
);

export const reasonsImages = {
  'banker.png': <BankerImage />,
  'black-dress-same.png': <BlackDressImage />,
  'justice.png': <JusticeImage />
};

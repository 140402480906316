import { useTrackingInstance } from '@rategravity/1pt-lib';
import { TrackingEvent } from '@rategravity/marketing-components';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  fonts
} from '@rategravity/own-up-component-library-legacy';
import { Link } from 'gatsby';
import React, { PropsWithChildren } from 'react';
import { colors } from '../../modules/colors';

type TrackingEventWithoutHook = Omit<TrackingEvent, 'hook'>;

interface OwnUpLinkProps {
  link: string;
  label: string;
  id?: string;
  trackingEvent?: TrackingEventWithoutHook;
  newWindow?: boolean;
}

export const OwnUpLink = ({
  link,
  label,
  children,
  id,
  trackingEvent,
  newWindow = false
}: PropsWithChildren<OwnUpLinkProps>) => {
  const trackingInstance = useTrackingInstance();
  return (
    <Link
      style={{ textDecoration: 'none' }}
      to={link}
      aria-label={label}
      id={id}
      onClick={() => {
        if (trackingEvent) {
          trackingInstance.track(trackingEvent.name, trackingEvent.properties);
        }
      }}
      {...(newWindow ? { target: '_none', rel: 'noreferrer' } : {})}
    >
      {children}
    </Link>
  );
};

const UnderlinedLinkStyle = createOwnUpStyle({
  cursor: 'pointer',
  position: 'relative',
  width: '100%',
  height: 'auto',
  textDecoration: 'none',
  backgroundPosition: '0% 100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '0% 2px',
  transition: 'background-size 0.3s ease-in-out',
  ':hover, :focus': {
    backgroundSize: '100% 2px'
  }
});

// Classic Blue color (default, but can take any color as prop) + Graphik font
const UnderlinedLinkClassicStyle = ({ color }: { color?: string }) =>
  createOwnUpStyle({
    ...UnderlinedLinkStyle,
    ...fonts.GRAPHIK_MEDIUM,
    fontFamily: "'Graphik-Medium', 'Arial', 'sans-serif'",
    color: color || colors.CLASSIC_BLUE,
    backgroundImage: `linear-gradient(${color || colors.CLASSIC_BLUE}, ${
      color || colors.CLASSIC_BLUE
    })`
  });

export const UnderlinedLink = createOwnUpComponent('a', UnderlinedLinkClassicStyle);

export const UnderlinedLinkInternal = createOwnUpComponent(Link, UnderlinedLinkClassicStyle);

export const LinkWrapper = createOwnUpComponent('div', {
  display: 'flex',
  justifyContent: 'center'
});
